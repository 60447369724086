import './Avatar.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({className, image}) => {
    let url = image ? `${image.baseUrl}${image.bucket}/896/${image.objectKey}` : '/img/avatar-placeholder-face.png';

    return <div className={`c-avatar ${className || ''}`} style={{backgroundImage: `url(${url})`}} />;
};

Avatar.propTypes = {
    className: PropTypes.string,
    image: PropTypes.object
};

export default Avatar;
