import Request from '../../../utils/request';
import PATHS from '../../../utils/paths';

import * as types from './constants';

export const clearEvent = () => ({
    type: types.CLEAR_EVENT
});

const getEventStart = () => ({
    type: types.GET_EVENT_START
});

const getEventSuccess = (data) => ({
    type: types.GET_EVENT_SUCCESS,
    data
});

const getEventFailure = (error) => ({
    type: types.GET_EVENT_FAILURE,
    error
});

export const setEvent = (data) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: types.SET_EVENT,
            data
        });
        resolve();
    });
};

export const getEvent = (postId) => (dispatch, getStore) => {
    dispatch(getEventStart());

    let request = new Request(process.env.token);

    return request.get(PATHS.event.getEvent(postId))
        .then((response) => {
            dispatch(getEventSuccess(response.data.data));
            return response;
        })
        .catch((err) => {
            err.friendlyMessage = 'Unable to get event.  Please try again';
            dispatch(getEventFailure(err));
            return err;
        })
}
