import clone from "clone";

export const createElementsObject = (elementsArray) => {
    return elementsArray.reduce((acc, el) => {
        const type = el.code;
        acc[type] = el;
        if(type === 'interests') {
            acc.interests.svgs = {};
            el.details.forEach((interest) => {
                acc.interests.svgs[interest.code] = interest.imgSvg;
            });
        }
        return acc;
    }, {});
}

export const sanitizeDetails = (object) => {
    if (!object.details) {
        return object;
    }
    const details = object.details.reduce((acc, el) => {

        const elementCode = el.element ? el.element.code : el.code;
        const item = el.elementDetail;

        if (!acc[elementCode]) {
            acc[elementCode] = [item];
            return acc;
        }
        if (acc[elementCode]) {
            if (acc[elementCode].length > 0) {
                acc[elementCode] = [ ...acc[elementCode], item ];
            } else {
                acc[elementCode] = [ acc[elementCode], item ];
            }
        }
        return acc;
    }, {})
    return {
        ...object,
        ...details,
    };
}

export const renderElement = (array) => {
    if (!Array.isArray(array)) {
        return `${array.description}`;
    }
    return array.map((item, index) => {
        if (index === array.length - 1) {
            return `${item.description}`;
        }
        return `${item.description}, `;
    })
}

export const addElement = (element, array) => {
    if (!array) {
        array = [];
    }
    return [ ...array, element ];
}

export const removeElement = (element, array) => {

    return array.map((el) => {
        if (el.elementDetailId === element.elementDetailId) {
            return { ...el, status: 'D'}
        }
        return el;
    });
}

/**
 * 
 * @param {array} detailArray detail array is from the profile object, read only
 * @param {array} elementArray element array is the array that the app changed
 * @param {array} elementId number of element item that was changed.
 * @return array with added items, and items to remove with a status of 'D'.
 */
export const prepareElementArray = (detailArray, elementArray, elementId) => {
    return [...detailArray, ...elementArray].reduce((acc, el) => {
        if (el.elementId !== elementId) {
            return [ ...acc, el ]
        }
        
        const elementIncludes = elementArray.findIndex(item => item.elementDetailId === el.elementDetailId);
        const detailIncludes = detailArray.findIndex(item => item.elementDetailId === el.elementDetailId );
        const duplicate = acc.findIndex(item => item.elementDetailId === el.elementDetailId);

        if (acc[duplicate]) {
            return acc;
        }

        if (detailArray[detailIncludes] && !elementArray[elementIncludes]) {
            el.status = 'D';
        }

        return [ ...acc, el ];

    }, [])
};

export const findElementInDetailsArray = (details, elementId) => {
    if(details == null || details.length === 0) {
        return null;
    } else {
        for(var i = 0; i < details.length; i++) {
            if(details[i].elementId === elementId) {
                return details[i];
            }
        }
    }
};

