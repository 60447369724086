import './Map.scss';

import React from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

class Map extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired
    };

    render() {
        let { location } = this.props;
        return (
            <GoogleMap
                defaultZoom={14}
                defaultCenter={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }}
            >
                <Marker position={{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }} />
            </GoogleMap>
        );
    }
}

export default withScriptjs(withGoogleMap(Map));
