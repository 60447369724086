import clone from 'clone';

import * as types from './constants';

//const sampleMarketplaceItem = {"postId":859,"groupId":1,"type":"I","title":"Test","description":"Test","enableRsvp":"N","price":10.00,"status":"A","createdAt":"2020-09-13T14:46:59.000+0000","likeCount":0,"commentCount":0,"rsvpCount":0,"redeemCount":0,"isLiked":false,"isRsvp":false,"isOwner":true,"isRedeemable":false,"details":[],"artifact":{"artifactId":1710,"objectKey":"update-your-app-video.jpg","bucket":"public-bucket-dev-media-processing","format":"mov","type":"video","width":"720","height":"1280","baseUrl":"https://s3.amazonaws.com/","imageDirectories":["220","568","667","736","812","896","original"],"videoUrl":"https://public-bucket-dev-media-processing.s3.amazonaws.com/video/12W3C9OAWkk5KqkoFQ/12W3C9OAWkk5KqkoFQ.m3u8","thumbnailUrl":"https://public-bucket-dev-media-processing.s3.amazonaws.com/video/12W3C9OAWkk5KqkoFQ/12W3C9OAWkk5KqkoFQthumbnail.0000000.jpg"},"profile":{"profileId":3,"emailAddress":"joshbroton@gmail.com","lastName":"Brotonz","firstName":"Joshuas","middleName":"","about":"Wouldn’t you like to know?","invisible":"false","status":"A","createdAt":"2019-06-17T22:18:55.000+0000","artifact":{"artifactId":1700,"objectKey":"hpuTkVh2YZ5doeR7ur.jpg","bucket":"public-bucket-dev-media-processing","format":"jpg","type":"image","width":"810.8709677419355","height":"1080","baseUrl":"https://s3.amazonaws.com/","imageDirectories":["220","568","667","736","812","896","original"]}},"group":{"groupId":1,"name":"School","description":"Campus Default Group","enroll":"A","defaultGroup":"true","memberStatus":"M"}}

export const initialState = {
    //marketplaceItem: clone(sampleMarketplaceItem),
    marketplaceItem: {},
    isGettingMarketplaceItem: false,
    getMarketplaceItemError: {}
};

function marketplaceReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_MARKETPLACE_ITEM:
            return { ...state, marketplaceItem: {} };
        case types.GET_MARKETPLACE_ITEM_START:
            return { ...state, isGettingMarketplaceItem: true, getMarketplaceItemError: {} };
        case types.GET_MARKETPLACE_ITEM_SUCCESS:
            return { ...state, isGettingMarketplaceItem: false, marketplaceItem: action.data };
        case types.GET_MARKETPLACE_ITEM_FAILURE:
            return { ...state, isGettingMarketplaceItem: false, getMarketplaceItemError: action.error };
        case types.SET_MARKETPLACE_ITEM:
            return { ...state, marketplaceItem: action.data };
        default:
            return state;
    }
}

export default marketplaceReducer;
