import './Error404.scss';

import React from 'react';

import DownloadButtons from '../../components/DownloadButtons';
import Logo from "../../components/Logo";

export default class Error404 extends React.Component {
    componentDidMount() {
        if(typeof document !== 'undefined') {
            document.body.classList.add('error-404-page');
        }
    }

    componentWillUnmount() {
        if(typeof document !== 'undefined') {
            document.body.classList.remove('error-404-page');
        }
    }

    render() {
        return (
            <div className="error-404">
                <div className="error-404__content">
                    <Logo type="black" />

                    <div className="error-404__title">
                        UH OH...404
                    </div>

                    <div className="error-404__text">
                        The content you’re looking for is no longer here...or perhaps it never was. In any case, sorry about that!
                    </div>

                    <DownloadButtons />
                </div>
            </div>
        );
    }
}
