import './Topic.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { generateImageUrl } from '../../utils/images';

import Icon from '../../components/Icon';
import PostHeader from '../../components/PostHeader';
import Date from "../../components/Date";
import DownloadButtons from "../../components/DownloadButtons";
import Video from "../../components/Video";

class Topic extends React.Component {
    componentDidMount() {
        if(typeof document !== 'undefined') {
            document.body.classList.add('thread-page');
        }

        if(!this.props.topic.topic.id || this.props.topic.postId !== this.props.match.params.postId) {
            //this.props.getMoment(this.props.match.params.postId);
        }
    }

    componentWillUnmount() {
        if(typeof document !== 'undefined') {
            document.body.classList.remove('thread-page');
        }
    }

    render() {
        let { isGettingTopic, topic } = this.props.topic;

        let artifact = topic.artifact ? topic.artifact : (topic.artifacts && topic.artifacts[0] ? topic.artifacts[0] : {});

        /*if(isGettingMoment || Object.keys(moment).length === 0) {
            return (
                <div className="post__loading-indicator">
                    <LoadingIndicator />
                </div>
            );
        } else {*/
            return (
                <div className="post thread">
                    <Helmet>
                        <title>{topic.name} | Abuzz Group</title>
                        <meta name="description" content={topic.name}/>
                        <meta property="og:url" content={process.env.REACT_APP_SITE_URL + this.props.match.url}/>
                        <meta property="og:title" content={topic.name}/>
                        <meta property="og:description" content={`${topic.name} | Abuzz Group`}/>
                    </Helmet>

                    <PostHeader/>

                    <div className="topic__content">
                        <div className="topic__header">
                            <div className="topic__header-left">
                                <div className="topic__category">
                                    {topic.createdByProfileId ? 'Student Group' : ''}
                                </div>
                                <div className="topic__name">
                                    {topic.name}
                                </div>
                            </div>
                            <div className="topic__header-right">
                                {topic.memberCount && (
                                    <div className="topic__metadata">
                                        <div className="topic__membercount">
                                            <strong>Members: </strong>{topic.memberCount}
                                        </div>
                                        {topic.threadCount && <><strong>Discussions: </strong>{topic.threadCount}</>}
                                    </div>
                                )}
                            </div>
                        </div>



                        {topic.privacyType !== 'private' ? (
                            <React.Fragment>
                                {topic.threads && topic.threads.length > 0 ? (
                                    <div className="topic__threads">
                                        {topic.threads.map((thread) => (
                                            <div className="topic__thread" key={thread.postId}>
                                                <div className="topic__thread-date">
                                                    <Date date={thread.createdAt} />
                                                </div>

                                                <div className="topic__thread-content">
                                                    <div className="topic__thread-title">{thread.title}</div>
                                                    {thread.description}
                                                </div>

                                                {thread.artifacts && thread.artifacts[0] && (
                                                    <div className="topic__thread-image">
                                                        <img src={generateImageUrl(thread.artifacts[0])} alt={thread.title} />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="topic__thread">
                                        No discussions... yet.
                                    </div>
                                )}
                            </React.Fragment>
                        ) : null}


                        <DownloadButtons
                            text="Join the conversation happening on your campus by downloading Abuzz"
                        />
                    </div>
                </div>
            );
        //}
    }
}

export default connect(
    ({topic}) => ({
        topic: topic
    }),
    dispatch => ({

    }))(Topic);
