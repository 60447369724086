import Request from '../../../utils/request';
import PATHS from '../../../utils/paths';

import * as types from './constants';
import { sampleElements } from "./reducers";

import { createElementsObject } from '../../../utils/elements';

export const clearElements = () => ({
    type: types.CLEAR_ELEMENTS
});

const getElementsStart = () => ({
    type: types.GET_ELEMENTS_START
});

const getElementsSuccess = (data) => ({
    type: types.GET_ELEMENTS_SUCCESS,
    data
});

const getElementsFailure = (error) => ({
    type: types.GET_ELEMENTS_FAILURE,
    error
});

export const setElements = (data) => (dispatch) => {
    const object = createElementsObject(data);

    return new Promise((resolve) => {
        dispatch({
            type: types.SET_ELEMENTS,
            elements: object,
            rawElements: data
        });
        resolve();
    });
};

export const getElements = (postId) => (dispatch, getStore) => {
    //dispatch(getElementsStart());

    let request = new Request(process.env.WEB_API_TOKEN);

    const object = createElementsObject(sampleElements);
    dispatch(getElementsSuccess({ elements: object, rawElements: sampleElements }));

    /*return request.get(PATHS.app.getElements(postId))
        .then((response) => {
            const object = createElementsObject(response.data);
            dispatch(getElementsSuccess({ elements: object, rawElements: response.data }));
            return response;
        })
        .catch((err) => {
            err.friendlyMessage = 'Unable to get elements.  Please try again';
            dispatch(getElementsFailure(err));
            return err;
        })*/
}
