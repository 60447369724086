import './AvatarRow.scss';

import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../components/Avatar';

export default class AvatarRow extends React.Component {
    static propTypes = {
        avatars: PropTypes.array,
        total: PropTypes.number
    };

    render() {
        let widthToUse = window.innerWidth <= 600 ? window.innerWidth : 600;
        let numberOfAvatars = Math.floor((widthToUse - 30) / 50);
        if(numberOfAvatars < this.props.total) {
            numberOfAvatars = numberOfAvatars - 1;
        }

        return (
            <div className="c-avatar-row">
                {this.props.avatars.map((p, i) => {
                    if(i < numberOfAvatars) {
                        let profile = p.profile || p;
                        return (
                            <div className="c-avatar-row__avatar" key={profile.profileId}>
                                <Avatar
                                    image={profile.artifact}
                                />
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}

                {numberOfAvatars < this.props.total ? (
                    <div className="c-avatar-row__profile-count">
                        +{this.props.total - numberOfAvatars}
                    </div>
                ) : null}
            </div>
        );
    }
}
