import Request from '../../../utils/request';
import PATHS from '../../../utils/paths';

import * as types from './constants';

export const clearMoment = () => ({
    type: types.CLEAR_MOMENT
});

const getMomentStart = () => ({
    type: types.GET_MOMENT_START
});

const getMomentSuccess = (data) => ({
    type: types.GET_MOMENT_SUCCESS,
    data
});

const getMomentFailure = (error) => ({
    type: types.GET_MOMENT_FAILURE,
    error
});

export const setMoment = (data) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: types.SET_MOMENT,
            data
        });
        resolve();
    });
};

export const getMoment = (postId) => (dispatch, getStore) => {
    dispatch(getMomentStart());

    let request = new Request(process.env.token);

    return request.get(PATHS.moment.getMoment(postId))
        .then((response) => {
            dispatch(getMomentSuccess(response.data.data));
            return response;
        })
        .catch((err) => {
            err.friendlyMessage = 'Unable to get moment.  Please try again';
            dispatch(getMomentFailure(err));
            return err;
        })
}