import 'normalize.css';
import 'scss/general.scss';

import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import Root from 'Root/Root';
import configureStore from "./store/configureStore";

const store = configureStore(window.__REDUX_STATE__ || {});

const rootEl = document.getElementById('root');

ReactDOM.render(
    <AppContainer>
        <Root store={store} />
    </AppContainer>,
    rootEl
);

if (module.hot) {
    module.hot.accept('Root/Root', () => {
        const NextRoot = require('Root/Root').default; // eslint-disable-line global-require
        ReactDOM.render(
            <AppContainer>
                <NextRoot store={store} />
            </AppContainer>,
            rootEl
        );
    });
}
