import clone from 'clone';

import * as types from './constants';

//const sampleDeal = {"postId":739,"groupId":19,"type":"D","title":"Business Meeting about Clubs and Food and Fun","description":"We're going to hold a business meeting to discuss clubs, food, and fun. We'll also be exploring the idea of Academics, Art, and News over drinks.","status":"A","createdAt":"2020-07-20T18:48:54.000+0000","redeem":"Y","redeemFrequency":"W","terms":"Redeem here","likeCount":0,"commentCount":0,"rsvpCount":0,"redeemCount":0,"isLiked":false,"isRsvp":false,"isOwner":false,"isVirtual":false,"isRedeemable":true,"location":{"name":"Sioux Falls Regional Airport","formattedAddress":"2801 N Jaycee Ln, Sioux Falls, SD 57104, USA","address1":"2801 N Jaycee Ln","city":"Sioux Falls","state":"SD","country":"US","lat":"43.5828065","lng":"-96.7402171","placeId":"ChIJaeutHlG1jocRL35m7pfTdRc","phoneNumber":"(605) 336-0762","artifact":{"artifactId":1228,"objectKey":"Ojs04R1zEqfnkQ6I_3sEtKz5t7aK7jvPU6kISUJmfKmhdIg_eiffel-tower.jpg","bucket":"public-bucket-dev-media-processing","type":"jpg","width":"874","height":"496","baseUrl":"https://s3.amazonaws.com/","imageDirectories":["220","568","667","736","812","896","original"]}},"artifact":{"artifactId":1227,"objectKey":"GgQT8qkA6OsHUKwK_Alone-tree-grass-field-lakePhotosPublic2109small.jpg","bucket":"public-bucket-dev-media-processing","type":"jpg","width":"800","height":"1200","baseUrl":"https://s3.amazonaws.com/","imageDirectories":["220","568","667","736","812","896","original"]},"group":{"groupId":19,"moderatorId":4,"name":"Coffee!!!","description":"Only the best way to start your day.","enroll":"O","defaultGroup":"false","memberStatus":"M","createdAt":"2019-06-20T18:30:45.000+0000","artifact":{"artifactId":32,"objectKey":"PorO71FGr2PM102H81.jpg","bucket":"public-bucket-dev-media-processing","type":"jpg","width":"1668","height":"2500","baseUrl":"https://s3.amazonaws.com/","imageDirectories":["220","568","667","736","812","896","original"]}}};

export const initialState = {
    //deal: clone(sampleDeal),
    deal: {},
    isGettingDeal: false,
    getDealError: {}
};

function dealReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_DEAL:
            return { ...state, deal: {} };
        case types.GET_DEAL_START:
            return { ...state, isGettingDeal: true, getDealError: {} };
        case types.GET_DEAL_SUCCESS:
            return { ...state, isGettingDeal: false, deal: action.data };
        case types.GET_DEAL_FAILURE:
            return { ...state, isGettingDeal: false, getDealError: action.error };
        case types.SET_DEAL:
            return { ...state, deal: action.data };
        default:
            return state;
    }
}

export default dealReducer;
