import './App.scss';

import React from 'react';
import { connect } from "react-redux";
import { Route, Switch } from "react-router";

import { getElements } from "./store/actions";

import Deal from '../Deal';
import Error404 from '../Error404';
import Event from '../Event';
import Group from '../Group';
import LoginVerify from '../Login';
//import LoadingIndicator from "../../components/LoadingIndicator";
import MarketplaceItem from '../Marketplace';
import Moment from '../Moment';
import SignupVerify from '../Verify';
import Thread from '../Thread';
import Topic from '../Topic';
import Invite from "../Invite";

class App extends React.Component {
    componentDidMount() {
        if(typeof document !== 'undefined') {
            document.body.classList.add('abuzz');
        }

        if(this.props.app.rawElements.length === 0) {
            this.props.getElements();
        }
    }

    componentWillUnmount() {
        if(typeof document !== 'undefined') {
            document.body.classList.remove('abuzz');
        }
    }

    render() {
        let { app } = this.props;

        /*if(app.isGettingElements || app.rawElements.length === 0) {
            return (
                <div className="post__loading-indicator">
                    <LoadingIndicator />
                </div>
            );
        } else {*/
            return (
                <>
                    <Switch>
                        <Route path="/deal/:dealId" component={Deal}/>
                        <Route path="/event/:postId" component={Event}/>
                        <Route path="/hive/:groupId" component={Group}/>
                        <Route path="/invite/:token" component={Invite}/>
                        <Route path="/admit-invite/:token" component={Invite}/>
                        <Route path="/login/:token" component={LoginVerify}/>
                        <Route path="/marketplace/:postId" component={MarketplaceItem} />
                        <Route path="/moment/:postId" component={Moment}/>
                        <Route path="/post/:postId" component={Moment}/>
                        <Route path="/forum/:postId" component={Thread}/>
                        <Route path="/group/:postId" component={Topic}/>
                        <Route path="/verify/:token" component={SignupVerify}/>
                        <Route component={Error404} />
                    </Switch>
                </>
            );
       /* }*/
    }
}

export default connect(
    ({app}) => ({
        app: app
    }),
    dispatch => ({
        getElements: (postId) => dispatch(getElements(postId))
    }))(App);
