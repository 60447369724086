import './Deal.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import clone from 'clone';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';

import { generateImageUrl } from "../../utils/images";
import { formatPhoneNumber, onlyNumbers } from "../../utils/data";

import { getDeal } from "./store/actions";

import AvatarRow from '../../components/AvatarRow';
import Icon from '../../components/Icon';
import LoadingIndicator from '../../components/LoadingIndicator';
import PostHeader from '../../components/PostHeader';
import Avatar from "../../components/Avatar";
import Map from '../../components/Map';

class Deal extends React.Component {
    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.body.classList.add('deal-page');
        }

        /*if (!this.props.event.event.id || this.props.event.event.id !== this.props.match.params.postId) {
            this.props.getEvent(this.props.match.params.postId);
        }*/
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.body.classList.remove('deal-page');
        }
    }

    generateImageUrl = (artifact) => {
        if (artifact) {
            return `${artifact.baseUrl}${artifact.bucket}/896/${artifact.objectKey}`;
        } else {
            //Josh is creating a generic social card
        }
    };

    renderLocation = (location) => {
        if(!location) {
            return null;
        }

        if(location.city && location.state) {
            return `${location.address1 ? location.address1 + ' ' : ''}${location.city} ${location.state}`;
        } else if(location.name) {
            return location.name;
        } else {
            return null;
        }
    };

    renderRedeemableString = (frequency) => {
        if(!frequency) {
            return 'Once';
        }
        const options = {
            D: 'Daily',
            W: 'Weekly',
            M: 'Monthly',
            Y: 'Yearly'
        };
        return options[frequency];
    };

    render() {
        let { isGettingDeal, deal } = this.props.deal;

        let artifact = deal.artifact ? deal.artifact : (deal.artifacts && deal.artifacts[0] ? deal.artifacts[0] : {});

        /*if (isGettingDeal || !deal || Object.keys(deal).length === 0) {
            return (
                <div className="post__loading-indicator">
                    <LoadingIndicator/>
                </div>
            );
        } else {*/
            return (
                <div className="post deal">
                    <Helmet>
                        <title>{`Abuzz Deal: ${deal.title}`}</title>
                        <meta name="description" content={deal.description}/>
                        <meta property="og:url" content={process.env.REACT_APP_SITE_URL + this.props.match.url}/>
                        <meta property="og:title" content={deal.title}/>
                        <meta property="og:description"
                              content={`An deal on Abuzz`}/>
                        <meta property="og:image" content={generateImageUrl(artifact)}/>
                    </Helmet>

                    <PostHeader/>

                    <div className="deal__content">
                        <div className="deal__image-wrapper">
                            <img src={generateImageUrl(artifact)} alt={`An image posted in ${deal.title}`}/>
                        </div>

                        <div className="deal__title-location-wrapper">
                            <div className="deal__title">
                                {deal.title}
                            </div>
                        </div>

                        {deal.location ? (
                            <>
                                {deal.location.name ? (
                                    <div className="deal__location-name">
                                        {deal.location.name}
                                    </div>
                                ) : null}

                                {deal.location.address1 || deal.location.city || deal.location.state ? (
                                    <div className="deal__location-address">
                                        {`${deal.location.address1 ? deal.location.address1 + ' ' : ''}${deal.location.city} ${deal.location.state}`}
                                    </div>
                                ) : null}

                                {deal.location.formattedAddress || deal.location.phoneNumber ? (
                                    <div className="deal__map-phone">
                                        {deal.location.formattedAddress ? (
                                            <a href={`https://maps.google.com/?q=${encodeURIComponent(deal.location.formattedAddress)}`} target="_blank">OPEN MAP</a>
                                        ) : null}

                                        {deal.location.formattedAddress && deal.location.phoneNumber ? <span> &#x2013; </span> : null}

                                        {deal.location.phoneNumber ? (
                                            <a href={`tel:${onlyNumbers(deal.location.phoneNumber)}`}>{formatPhoneNumber(deal.location.phoneNumber)}</a>
                                        ) : null}
                                    </div>
                                ) : null}
                            </>
                        ) : null}

                        <div className="deal__description">
                            {deal.description}
                        </div>

                        {deal.terms ? (
                            <div className="deal__terms">
                                <strong>Details & Redemption</strong>

                                {deal.terms}
                            </div>
                        ) : null}

                        {deal.redeem === 'Y' ? (
                            <div className="deal__redeem">
                                Redeemable {this.renderRedeemableString(deal.redeemFrequency)}
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        /*}*/
    }
}

export default connect(
    ({app, deal}) => ({
        deal: deal
    }),
    dispatch => ({
        getDeal: (postId) => dispatch(getDeal(postId))
    }))(Deal);
