import './Event.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import clone from 'clone';
import moment from 'moment';
import GoogleMapReact from 'google-map-react';

import { generateImageUrl } from "../../utils/images";

import { getEvent } from "./store/actions";

import AvatarRow from '../../components/AvatarRow';
import Icon from '../../components/Icon';
import LoadingIndicator from '../../components/LoadingIndicator';
import PostHeader from '../../components/PostHeader';
import Avatar from "../../components/Avatar";
import Map from '../../components/Map';
import Video from "../../components/Video";

class Event extends React.Component {
    componentDidMount() {
        if (typeof document !== 'undefined') {
            document.body.classList.add('event-page');
        }

        /*if (!this.props.event.event.id || this.props.event.event.id !== this.props.match.params.postId) {
            this.props.getEvent(this.props.match.params.postId);
        }*/
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.body.classList.remove('event-page');
        }
    }

    generateImageUrl = (artifact) => {
        if (artifact) {
            return `${artifact.baseUrl}${artifact.bucket}/896/${artifact.objectKey}`;
        } else {
            //Josh is creating a generic social card
        }
    };

    getFoodSelection = () => {
        let details = clone(this.props.event.event.details);
        if(details == null || details.length === 0) {
            return null;
        } else {
            for(var i = 0; i < details.length; i++) {
                if(details[i].elementId === this.props.foodOptions.elementId) {
                    let id = details[i].elementDetailId;
                    return this.props.foodOptions.details.filter((deet) => id === deet.elementDetailId)[0];
                }
            }
        }
    };

    renderLocation = (location) => {
        if(!location) {
            return null;
        }

        if(location.city && location.state) {
            return `${location.address1 ? location.address1 + ' ' : ''}${location.city} ${location.state}`;
        } else if(location.name) {
            return location.name;
        } else {
            return null;
        }
    };

    render() {
        let { isGettingEvent, event } = this.props.event;

        let artifact = event.artifact ? event.artifact : (event.artifacts && event.artifacts[0] ? event.artifacts[0] : {});

        /*if (isGettingEvent || !event || Object.keys(event).length === 0) {
            return (
                <div className="post__loading-indicator">
                    <LoadingIndicator/>
                </div>
            );
        } else {*/
            let foodSelection = this.getFoodSelection();
            let categories = event.details ? event.details.filter(d => d.elementId === this.props.interestsElementId) : [];

            return (
                <div className="post event">
                    <Helmet>
                        <title>{`Abuzz Event: ${event.title}`}</title>
                        <meta name="description" content={event.description}/>
                        <meta property="og:url" content={process.env.REACT_APP_SITE_URL + this.props.match.url}/>
                        <meta property="og:title" content={event.title}/>
                        <meta property="og:description"
                              content={`An event on Abuzz`}/>
                        <meta property="og:image" content={generateImageUrl(artifact)}/>
                    </Helmet>

                    <PostHeader/>

                    {artifact.type === 'video' ? (
                        <Video
                            artifact={artifact}
                        />
                    ) : (
                        <div className="event__image-wrapper">
                            <img src={generateImageUrl(artifact)} alt={`An image posted in ${event.title}`}/>
                        </div>
                    )}

                    <div className="event__title-location-wrapper">
                        <div className="event__title">
                            {event.title}
                        </div>
                    </div>

                    <div className="event__content">
                        <div className="event__overview-text">
                            {event.description}
                        </div>

                        {foodSelection ? (
                            <div className={`event__food-promo event__food-promo--${foodSelection.code}`}>
                                {foodSelection.code === 'free' ? (
                                    <React.Fragment>
                                        <div className="event__food-promo-badge event__food-promo-badge--free">
                                            Free Food
                                        </div>

                                        <div className="event__food-promo-text">
                                            With your Student ID
                                        </div>
                                    </React.Fragment>
                                ) : foodSelection.code === 'promo' ? (
                                    <React.Fragment>
                                        <div className="event__food-promo-badge event__food-promo-badge--promo">
                                            Food Promo
                                        </div>

                                        <div className="event__food-promo-text">
                                            Discount with Student ID
                                        </div>
                                    </React.Fragment>
                                ) : null}
                            </div>
                        ) : null}

                        <div className="event__content-title">
                            Time & Date
                        </div>

                        <div className="event__content-text">
                            {moment(event.startAt).format('dddd, MMM. D, YYYY, h:mma')}{event.endAt ? ' - ' : ''}
                            {event.endAt ? (<div>{moment(event.endAt).format('dddd, MMM. D, YYYY, h:mma')}</div>) : null}
                        </div>

                        {categories.length > 0 ? (
                            <React.Fragment>
                                <div className="event__content-title">
                                    For people who like
                                </div>

                                <div className="event__content-text">
                                    {categories.map(c => c.displayText || c.elementDetail.displayText).join(', ')}
                                </div>
                            </React.Fragment>
                        ) : null}

                        <div className="event__content-title">
                            Location
                        </div>

                        <div className="event__content-text">
                            {event.location && event.location.address1 && event.location.name ? (
                                <div className="event__location-name">
                                    {event.location.name}
                                </div>
                            ) : null}
                            <div className="event__location-address">
                                {this.renderLocation(event.location)}
                            </div>
                        </div>

                        {event.location && event.location.lat != null && event.location.lng != null ? (
                            <div className="event__map">
                                <Map
                                    location={event.location}
                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB0oEdhLw6VG4BDtzMnAS4PTUMHGBb_H1o&v=3.exp&libraries=geometry,drawing,places"
                                    loadingElement={<div style={{ height: `100%` }} />}
                                    containerElement={<div style={{ height: `300px` }} />}
                                    mapElement={<div style={{ height: `100%` }} />}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            );
        /*}*/
    }
}

export default connect(
    ({app, event}) => ({
        event: event,
        foodOptions: app.elements.food,
        interestsElementId: app.elements.interests.elementId
    }),
    dispatch => ({
        getEvent: (postId) => dispatch(getEvent(postId))
    }))(Event);
