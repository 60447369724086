import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { appReducer } from './reducers';

let composeEnhancers = null;

if(typeof window != 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ != null) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
} else {
    composeEnhancers = compose;
}
const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunkMiddleware))(createStore);

export default function configureStore(initialState = {}) {
    return createStoreWithMiddleware(appReducer, initialState);
};