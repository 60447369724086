import './Thread.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { generateImageUrl } from '../../utils/images';

import { getMarketplaceItem } from "./store/actions";

import Hashtag from "../../components/Hashtag";
import Icon from '../../components/Icon';
import LoadingIndicator from '../../components/LoadingIndicator';
import PostHeader from '../../components/PostHeader';
import Date from "../../components/Date";
import DownloadButtons from "../../components/DownloadButtons";
import Video from "../../components/Video";

class Thread extends React.Component {
    componentDidMount() {
        if(typeof document !== 'undefined') {
            document.body.classList.add('thread-page');
        }

        if(!this.props.thread.thread.id || this.props.thread.postId !== this.props.match.params.postId) {
            //this.props.getMoment(this.props.match.params.postId);
        }
    }

    componentWillUnmount() {
        if(typeof document !== 'undefined') {
            document.body.classList.remove('thread-page');
        }
    }

    render() {
        let { isGettingThread, thread } = this.props.thread;

        let artifact = thread.artifact ? thread.artifact : (thread.artifacts && thread.artifacts[0] ? thread.artifacts[0] : {});

        /*if(isGettingMoment || Object.keys(moment).length === 0) {
            return (
                <div className="post__loading-indicator">
                    <LoadingIndicator />
                </div>
            );
        } else {*/
            return (
                <div className="post thread">
                    <Helmet>
                        <title>Abuzz Forum</title>
                        <meta name="description" content={thread.description}/>
                        <meta property="og:url" content={process.env.REACT_APP_SITE_URL + this.props.match.url}/>
                        <meta property="og:title" content={thread.title}/>
                        <meta property="og:description" content={`Abuzz Marketplace Item for Sale`}/>
                        <meta property="og:image" content={generateImageUrl(artifact)}/>
                    </Helmet>

                    <PostHeader/>

                    <div className="thread__content">
                        <div className="thread__date-forum">
                            <strong>Discussion</strong>{thread.forumTopic && thread.forumTopic.name ? <> in <strong>{thread.forumTopic.name}</strong></> : null}

                            <div className="thread__date">
                                <Date date={thread.createdAt} />
                            </div>
                        </div>
                        {artifact && Object.keys(artifact).length > 0 && (
                            <>
                                {artifact.type === 'video' ? (
                                    <Video
                                        artifact={artifact}
                                        containerPadding={40}
                                    />
                                ) : (
                                    <div className="thread__image-wrapper">
                                        <img src={generateImageUrl(artifact)} alt={`An image posted of ${thread.title}`}/>
                                    </div>
                                )}
                            </>
                        )}

                        {thread.title != null && (
                            <div className="thread__title">
                                {thread.title}
                            </div>
                        )}

                        <div className="thread__description">
                            {thread.description}
                        </div>

                        <div className="thread__metadata">
                            <div className="thread__metadata-comments">
                                {thread.threadComments && thread.threadComments.length > 0 && (
                                    <React.Fragment>
                                        <Icon type="comment" /> {thread.threadComments ? thread.threadComments.length : 0} comment{thread.threadComments && thread.threadComments.length !== 1 ? 's' : ''}
                                    </React.Fragment>
                                )}
                            </div>

                            <div className="thread__metadata-upvotes">
                                {thread.likeCount} <Icon type="upvote-solid" />
                            </div>
                        </div>

                        {thread.threadComments && thread.threadComments.length > 0 && (
                            <div className="thread__comments">
                                {thread.threadComments.map((c) => (
                                    <div className="thread__comment">
                                        <div className="thread__comment-date">
                                            <Date date={c.createdAt} />
                                        </div>

                                        <div className="thread__comment-content">
                                            {c.comment}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <DownloadButtons
                            text="Join the conversation happening on your campus by downloading Abuzz"
                        />
                    </div>
                </div>
            );
        //}
    }
}

export default connect(
    ({thread}) => ({
        thread: thread
    }),
    dispatch => ({

    }))(Thread);
