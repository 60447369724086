import './Date.scss';

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

export default class Date extends React.PureComponent {
    static propTypes = {
        dontAppendText: PropTypes.bool,
        date: PropTypes.string.isRequired
    };

    constructor() {
        super();

        this.state = {
            refreshSwitch: false
        };

        this.refreshInterval = null;
    }

    componentDidMount() {
        this.refreshInterval = setInterval(() => this.setState({refreshSwitch: !this.state.refreshSwitch}), 60000)
    }

    componentWillUnmount() {
        window.clearInterval(this.refreshInterval);
    }

    translateValue = (val) => {
        if(/a|an/.test(val)) {
            return 1
        }

        return val;
    };

    render() {
        const dateString = moment(this.props.date).fromNow();
        const dateArray = dateString.split(' ');
        let newDateString = `${this.translateValue(dateArray[0])}${/month|months/.test(dateArray[1]) ? 'mo' : dateArray[1][0]}${this.props.dontAppendText !== true ? ` ${dateArray[2]}` : ''}`;
        if(dateString === 'a few seconds ago') {
            newDateString = 'now';
        }

        return (
            <span className={`c-date ${this.props.className || ''}`}>
                {newDateString}
            </span>
        )
    }
}
