const API_URL = process.env.REACT_APP_API_URL;

const PATHS = {
    app: {
        getElements: () => 'element',
    },
    event: {
        getEvent: (postId) => `post/event/${postId}`,
    },
    group: {
        getGroup: (groupId) => `group/${groupId}`
    },
    marketplace: {
        getMarketplaceItem: (postId) => `post/item/${postId}`
    },
    moment: {
        getMoment: (postId) => `post/moment/${postId}`,
    },
    thread: {
        getThread: (postId) => `post/thread/${postId}`,
    }
};

export default PATHS;
