import './Verify.scss';

import React from 'react';

import Logo from '../../components/Logo';

const Verify = () => {
    return (
        <div className="error-404">
            <div className="error-404__content">
                <Logo type="black" />

                <div className="error-404__title">
                    UH OH... A BROKEN MAGIC LINK
                </div>

                <div className="error-404__text">
                    Please use the code we sent with the magic link to verify your email address manually in the app.
                </div>
            </div>
        </div>
    );
};

export default Verify;
