import './Login.scss';

import React from 'react';

import Logo from '../../components/Logo';

const Login = () => {
    return (
        <div className="error-404">
            <div className="error-404__content">
                <Logo type="black" />

                <div className="error-404__title">
                    UH OH... A BROKEN MAGIC LINK
                </div>

                <div className="error-404__text">
                    Please open the app and sign in using your email address and password.
                </div>
            </div>
        </div>
    );
};

export default Login;
