import './Moment.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { generateImageUrl } from '../../utils/images';

import { getMoment } from "./store/actions";

import Comment from '../../components/Comment';
import Icon from '../../components/Icon';
import LoadingIndicator from '../../components/LoadingIndicator';
import PostHeader from '../../components/PostHeader';
import Video from "../../components/Video";

class Moment extends React.Component {
    componentDidMount() {
        if(typeof document !== 'undefined') {
            document.body.classList.add('moment-page');
        }

        if(!this.props.moment.moment.id || this.props.moment.moment.id !== this.props.match.params.postId) {
            //this.props.getMoment(this.props.match.params.postId);
        }


    }

    componentWillUnmount() {
        if(typeof document !== 'undefined') {
            document.body.classList.remove('moment-page');
        }
    }

    render() {
        let { isGettingMoment, moment } = this.props.moment;
        let comments = moment.details ? moment.details.filter((d) => d.type === 'C') : [];

        /*if(isGettingMoment || Object.keys(moment).length === 0) {
            return (
                <div className="post__loading-indicator">
                    <LoadingIndicator />
                </div>
            );
        } else {*/
            return (
                <div className="post">
                    <Helmet>
                        <title>Abuzz post</title>
                        <meta name="description" content={moment.description}/>
                        <meta property="og:url" content={process.env.REACT_APP_SITE_URL + this.props.match.url}/>
                        <meta property="og:title" content={moment.description}/>
                        <meta property="og:description"
                              content={`Abuzz post`}/>
                        <meta property="og:image" content={generateImageUrl(moment.artifact)}/>
                    </Helmet>

                    <PostHeader/>

                    {moment.artifact ? (
                        <div className="moment__content--has-image">
                            {moment.artifact.type === 'video' ? (
                                <Video
                                    artifact={moment.artifact}
                                />
                            ) : (
                                <div className="moment__image-wrapper">
                                    <img src={generateImageUrl(moment.artifact)} alt={`An image posted in a moment`}/>
                                </div>
                            )}

                            {moment.location && (moment.location.name || moment.location.formattedAddress) ? (
                                <div className="moment__location">
                                    {moment.location.name || moment.location.formattedAddress}
                                </div>
                            ) : null}
                        </div>
                    ) : null}

                    <Comment
                        comment={moment.description}
                        profile={moment.profile}
                        timestamp={moment.createdAt}
                    />

                    {/*{moment.details.length > 0 ? (
                        <div className="moment__comments">
                            {comments.map((comment) => (
                                <Comment
                                    comment={comment.comment}
                                    key={comment.postDetailId}
                                    profile={comment.profile}
                                    timestamp={comment.createdAt}
                                />
                            ))}
                        </div>
                    ) : null}*/}
                </div>
            );
        //}
    }
}

export default connect(
    ({moment}) => ({
        moment: moment
    }),
    dispatch => ({
        getMoment: (postId) => dispatch(getMoment(postId))
    }))(Moment);
