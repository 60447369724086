import './Invite.scss';

import React from 'react';

import Logo from '../../components/Logo';
import {Helmet} from "react-helmet";

const Invite = ({match}) => {
    return (
        <div className="error-404">
            <Helmet>
                <title>Abuzz Invitation</title>
                <meta name="description" content="A landing page for invitations to Abuzz that don't open in the app."/>
                <meta property="og:url" content={process.env.REACT_APP_SITE_URL + match.url}/>
                <meta property="og:title" content={"Abuzz App Invitation"}/>
                <meta property="og:description" content="A landing page for invitations to Abuzz that don't open in the app."/>
            </Helmet>

            <div className="error-404__content">
                <Logo type="black" />

                <div className="error-404__title">
                    UH OH... YOUR INVITATION LINK ISN'T WORKING
                </div>

                <div className="error-404__text">
                    Technology, amirite? It's not a big deal. Just jump in the app and sign up using your email. We'll make sure you get to the right place.
                </div>
            </div>
        </div>
    );
};

export default Invite;
