export const formatPhoneNumber = (phoneNumber) => {
    let nbrs = onlyNumbers(phoneNumber);
    if(nbrs.length === 10) {
        return `${nbrs.substr(0, 3)}-${nbrs.substr(3, 3)}-${nbrs.substr(6, 4)}`
    } else {
        return phoneNumber;
    }
};

export const onlyNumbers = (value) => {
    return value.replace(/\D/g, '');
};
