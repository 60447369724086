import './Logo.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({type}) => {
    if(type === 'wordmark') {
        return (
            <svg className="c-logo a-logo--wordmark" viewBox="0 0 181 50.69">
                <path d="M61.65,15.78c-5.28,0-8.84,2.22-11,5.67h-.1V0H41.85V49.57h8.74V45h.2c2,3.44,5.59,5.67,11,5.67,8.63,0,15.74-6.27,15.74-17.4S70.29,15.78,61.65,15.78ZM59.52,42.39c-5.28,0-8.84-3.64-8.84-9.21S54.34,24,59.52,24s8.84,3.64,8.84,9.21S64.7,42.39,59.52,42.39Z"/>
                <path d="M26.71,21.45c-2-3.44-5.59-5.67-11-5.67C7.11,15.78,0,22.05,0,33.18s7.21,17.4,15.74,17.4c5.28,0,8.84-2.23,11-5.67h.1v4.55h8.74V16.9H26.92v4.55ZM18,42.39c-5.28,0-8.84-3.64-8.84-9.21S12.8,24,18,24s8.84,3.64,8.84,9.21S23.26,42.39,18,42.39Z"/>
                <path d="M97.71,42.59c4.27,0,7.21-2.83,7.21-10h.1V16.9h8.74V49.67h-8.63V44.62H105a11.14,11.14,0,0,1-10.26,6.07c-6.7,0-12.19-4-12.19-13.76v-20h8.94V35.31C91.52,40.37,94,42.59,97.71,42.59Z"/>
                <path d="M146.06,23.77V16.9H118.43v7.59H135.5L118.74,42.59v7h28.54V41.88h-18Z"/>
                <path d="M179.78,23.77,163,41.88h18v7.69H152.46v-7l16.66-18.11h-17V16.9h27.63Z"/>
            </svg>
        );
    } else if(type === 'icon') {
        return (
            <svg className="c-logo a-logo--icon" viewBox="0 0 102.99 83.27">
                <path d="M100.76,9.31a44.84,44.84,0,0,0-45.4-5.46C54,4.46,52.82,5.06,51.6,5.77c-1.22-.71-2.54-1.32-3.76-1.92A44.61,44.61,0,0,0,2.44,9.31L0,11.13.81,14a53,53,0,0,0,28,32.37A44.51,44.51,0,0,0,49.16,81.75l2.34,1.52,2.34-1.52A44.25,44.25,0,0,0,74.15,46.34a53,53,0,0,0,28-32.37l.81-2.83ZM51.6,16.09a35.71,35.71,0,0,1,8.33,9.11,28.67,28.67,0,0,1-8.33,1.32,25.63,25.63,0,0,1-8.33-1.32A35.54,35.54,0,0,1,51.6,16.09ZM65.41,40.57A44.88,44.88,0,0,1,51.6,42.8a44.88,44.88,0,0,1-13.81-2.23,37.88,37.88,0,0,1,1.63-7.49,37.41,37.41,0,0,0,12.09,2,37.41,37.41,0,0,0,12.09-2A32.22,32.22,0,0,1,65.41,40.57ZM38,49.68a51.77,51.77,0,0,0,27.22,0,34.39,34.39,0,0,1-2.34,8.4,60.36,60.36,0,0,1-22.55,0A33.18,33.18,0,0,1,38,49.68ZM10.26,14.37a36,36,0,0,1,33.32-2.93A45.12,45.12,0,0,0,29.46,36.83,44,44,0,0,1,10.26,14.37ZM73.64,36.83a44.34,44.34,0,0,0-14-25.39,36,36,0,0,1,33.32,2.93A44.21,44.21,0,0,1,73.64,36.83Z"/>
            </svg>
        );
    } else if(type === 'black') {
        return (
            <svg className="c-logo a-logo--icon-wordmark a-logo--icon-wordmark-black" viewBox="0 0 181 138">
                <path className="letter" d="M61.65,103.1c-5.28,0-8.84,2.22-11,5.67h-.1V87.31H41.85v49.57h8.74v-4.55h.2c2,3.44,5.59,5.67,11,5.67,8.63,0,15.74-6.27,15.74-17.4S70.29,103.1,61.65,103.1ZM59.52,129.7c-5.28,0-8.84-3.64-8.84-9.21s3.66-9.21,8.84-9.21,8.84,3.64,8.84,9.21S64.7,129.7,59.52,129.7Z"/>
                <path className="letter" d="M26.71,108.76c-2-3.44-5.59-5.67-11-5.67C7.11,103.1,0,109.37,0,120.5s7.21,17.4,15.74,17.4c5.28,0,8.84-2.23,11-5.67h.1v4.55h8.74V104.21H26.92v4.55ZM18,129.7c-5.28,0-8.84-3.64-8.84-9.21s3.66-9.21,8.84-9.21,8.84,3.64,8.84,9.21S23.26,129.7,18,129.7Z"/>
                <path className="letter" d="M97.71,129.91c4.27,0,7.21-2.83,7.21-10h.1V104.21h8.74V137h-8.63v-5.06H105A11.14,11.14,0,0,1,94.77,138c-6.7,0-12.19-4-12.19-13.76v-20h8.94v18.41C91.52,127.68,94,129.91,97.71,129.91Z"/>
                <path className="letter" d="M146.06,111.09v-6.88H118.43v7.59H135.5l-16.76,18.11v7h28.54V129.2h-18Z"/>
                <path className="letter" d="M179.78,111.09,163,129.2h18v7.69H152.46v-7l16.66-18.11h-17v-7.59h27.63Z"/>
                <path d="M139.66,9.31a44.84,44.84,0,0,0-45.4-5.46c-1.32.61-2.54,1.21-3.76,1.92C89.28,5.06,88,4.46,86.74,3.85a44.61,44.61,0,0,0-45.4,5.46L38.9,11.13,39.71,14a53,53,0,0,0,28,32.37A44.51,44.51,0,0,0,88.06,81.75l2.34,1.52,2.34-1.52A44.25,44.25,0,0,0,113,46.34a53,53,0,0,0,28-32.37l.81-2.83ZM90.5,16.09a35.71,35.71,0,0,1,8.33,9.11,28.67,28.67,0,0,1-8.33,1.32,25.63,25.63,0,0,1-8.33-1.32A35.54,35.54,0,0,1,90.5,16.09Zm13.81,24.48A44.88,44.88,0,0,1,90.5,42.8a44.88,44.88,0,0,1-13.81-2.23,37.88,37.88,0,0,1,1.63-7.49,37.41,37.41,0,0,0,12.09,2,37.41,37.41,0,0,0,12.09-2A32.22,32.22,0,0,1,104.31,40.57ZM76.89,49.68a51.77,51.77,0,0,0,27.22,0,34.39,34.39,0,0,1-2.34,8.4,60.36,60.36,0,0,1-22.55,0A33.18,33.18,0,0,1,76.89,49.68ZM49.16,14.37a36,36,0,0,1,33.32-2.93A45.12,45.12,0,0,0,68.36,36.83,44,44,0,0,1,49.16,14.37Zm63.38,22.46a44.34,44.34,0,0,0-14-25.39,36,36,0,0,1,33.32,2.93A44.21,44.21,0,0,1,112.54,36.83Z"/>
            </svg>
        );
    } else {
        return (
            <svg className="c-logo a-logo--icon-wordmark" viewBox="0 0 181 138">
                <path d="M61.65,103.1c-5.28,0-8.84,2.22-11,5.67h-.1V87.31H41.85v49.57h8.74v-4.55h.2c2,3.44,5.59,5.67,11,5.67,8.63,0,15.74-6.27,15.74-17.4S70.29,103.1,61.65,103.1ZM59.52,129.7c-5.28,0-8.84-3.64-8.84-9.21s3.66-9.21,8.84-9.21,8.84,3.64,8.84,9.21S64.7,129.7,59.52,129.7Z"/>
                <path d="M26.71,108.76c-2-3.44-5.59-5.67-11-5.67C7.11,103.1,0,109.37,0,120.5s7.21,17.4,15.74,17.4c5.28,0,8.84-2.23,11-5.67h.1v4.55h8.74V104.21H26.92v4.55ZM18,129.7c-5.28,0-8.84-3.64-8.84-9.21s3.66-9.21,8.84-9.21,8.84,3.64,8.84,9.21S23.26,129.7,18,129.7Z"/>
                <path d="M97.71,129.91c4.27,0,7.21-2.83,7.21-10h.1V104.21h8.74V137h-8.63v-5.06H105A11.14,11.14,0,0,1,94.77,138c-6.7,0-12.19-4-12.19-13.76v-20h8.94v18.41C91.52,127.68,94,129.91,97.71,129.91Z"/>
                <path d="M146.06,111.09v-6.88H118.43v7.59H135.5l-16.76,18.11v7h28.54V129.2h-18Z"/>
                <path d="M179.78,111.09,163,129.2h18v7.69H152.46v-7l16.66-18.11h-17v-7.59h27.63Z"/>
                <path d="M139.66,9.31a44.84,44.84,0,0,0-45.4-5.46c-1.32.61-2.54,1.21-3.76,1.92C89.28,5.06,88,4.46,86.74,3.85a44.61,44.61,0,0,0-45.4,5.46L38.9,11.13,39.71,14a53,53,0,0,0,28,32.37A44.51,44.51,0,0,0,88.06,81.75l2.34,1.52,2.34-1.52A44.25,44.25,0,0,0,113,46.34a53,53,0,0,0,28-32.37l.81-2.83ZM90.5,16.09a35.71,35.71,0,0,1,8.33,9.11,28.67,28.67,0,0,1-8.33,1.32,25.63,25.63,0,0,1-8.33-1.32A35.54,35.54,0,0,1,90.5,16.09Zm13.81,24.48A44.88,44.88,0,0,1,90.5,42.8a44.88,44.88,0,0,1-13.81-2.23,37.88,37.88,0,0,1,1.63-7.49,37.41,37.41,0,0,0,12.09,2,37.41,37.41,0,0,0,12.09-2A32.22,32.22,0,0,1,104.31,40.57ZM76.89,49.68a51.77,51.77,0,0,0,27.22,0,34.39,34.39,0,0,1-2.34,8.4,60.36,60.36,0,0,1-22.55,0A33.18,33.18,0,0,1,76.89,49.68ZM49.16,14.37a36,36,0,0,1,33.32-2.93A45.12,45.12,0,0,0,68.36,36.83,44,44,0,0,1,49.16,14.37Zm63.38,22.46a44.34,44.34,0,0,0-14-25.39,36,36,0,0,1,33.32,2.93A44.21,44.21,0,0,1,112.54,36.83Z"/>
            </svg>
        );
    }
};

Logo.propTypes = {
    type: PropTypes.oneOf([
        'wordmark',
        'icon',
        'black'
    ])
};

export default Logo;
