import './Marketplace.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import ReactHashtag from 'react-hashtag';

import { generateImageUrl } from '../../utils/images';

import { getMarketplaceItem } from "./store/actions";

import Hashtag from "../../components/Hashtag";
import Icon from '../../components/Icon';
import LoadingIndicator from '../../components/LoadingIndicator';
import PostHeader from '../../components/PostHeader';
import Date from "../../components/Comment";
import DownloadButtons from "../../components/DownloadButtons";
import Video from "../../components/Video";

class MarketplaceItem extends React.Component {
    componentDidMount() {
        if(typeof document !== 'undefined') {
            document.body.classList.add('marketplace-item-page');
        }

        if(!this.props.marketplace.marketplaceItem.id || this.props.marketplace.marketplaceItem.id !== this.props.match.params.postId) {
            //this.props.getMoment(this.props.match.params.postId);
        }
    }

    componentWillUnmount() {
        if(typeof document !== 'undefined') {
            document.body.classList.remove('marketplace-item-page');
        }
    }

    render() {
        let { isGettingMarketplaceItem, marketplaceItem } = this.props.marketplace;

        let artifact = marketplaceItem.artifact ? marketplaceItem.artifact : (marketplaceItem.artifacts && marketplaceItem.artifacts[0] ? marketplaceItem.artifacts[0] : {});

        /*if(isGettingMoment || Object.keys(moment).length === 0) {
            return (
                <div className="post__loading-indicator">
                    <LoadingIndicator />
                </div>
            );
        } else {*/
            return (
                <div className="post marketplace-item">
                    <Helmet>
                        <title>Abuzz post</title>
                        <meta name="description" content={marketplaceItem.description}/>
                        <meta property="og:url" content={process.env.REACT_APP_SITE_URL + this.props.match.url}/>
                        <meta property="og:title" content={marketplaceItem.title}/>
                        <meta property="og:description" content={`Abuzz Marketplace Item for Sale`}/>
                        <meta property="og:image" content={generateImageUrl(artifact)}/>
                    </Helmet>

                    <PostHeader/>

                    <div className="marketplace-item__content">
                        {artifact.type === 'video' ? (
                            <Video
                                artifact={artifact}
                                containerPadding={40}
                            />
                        ) : (
                            <div className="marketplace-item__image-wrapper">
                                <img src={generateImageUrl(artifact)} alt={`An image posted of ${marketplaceItem.title}`}/>
                            </div>
                        )}

                        <div className="marketplace-item__title">
                            {marketplaceItem.title}
                        </div>

                        {marketplaceItem.freeItem === 'Y' ? (
                            <div className={`marketplace-item__price marketplace-item__price--free ${marketplaceItem.soldAt ? 'marketplace-item__price--sold' : ''}`}>
                                Free {marketplaceItem.soldAt ? <span className="marketplace-item__sold">Sold</span> : null}
                            </div>
                        ) : (
                            <div className={`marketplace-item__price ${marketplaceItem.soldAt ? 'marketplace-item__price--sold' : ''}`}>
                                $ {marketplaceItem.price} {marketplaceItem.soldAt ? <span className="marketplace-item__sold">Sold</span> : null}
                            </div>
                        )}

                        {marketplaceItem.description ? (
                            <div className="marketplace-item__description">
                                <ReactHashtag renderHashtag={hashtagValue => <Hashtag>{hashtagValue}</Hashtag>}>{marketplaceItem.description}</ReactHashtag>
                            </div>
                        ) : null}

                        <DownloadButtons
                            text="Find more items for sale around your campus by downloading Abuzz"
                        />
                    </div>

                    {/*{moment.details.length > 0 ? (
                        <div className="moment__comments">
                            {comments.map((comment) => (
                                <Comment
                                    comment={comment.comment}
                                    key={comment.postDetailId}
                                    profile={comment.profile}
                                    timestamp={comment.createdAt}
                                />
                            ))}
                        </div>
                    ) : null}*/}
                </div>
            );
        //}
    }
}

export default connect(
    ({marketplace}) => ({
        marketplace: marketplace
    }),
    dispatch => ({

    }))(MarketplaceItem);
