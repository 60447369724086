import './Group.scss';

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import ReactHashtag from 'react-hashtag';

import { generateImageUrl } from '../../utils/images';
import { sanitizeDetails } from "../../utils/elements";

import { getGroup } from "./store/actions";

import Hashtag from "../../components/Hashtag";

class Group extends React.Component {
    componentDidMount() {
        if(typeof document !== 'undefined') {
            document.body.classList.add('group-page');
        }

        if(!this.props.group.group.groupId || this.props.group.group.groupId.toString() !== this.props.match.params.groupId) {
            //this.props.getGroup(this.props.match.params.groupId);
        }
    }

    componentWillUnmount() {
        if(typeof document !== 'undefined') {
            document.body.classList.remove('group-page');
        }
    }

    handleLinkTap = () => {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            window.open('http://play.google.com/store/apps/details?id=com.campusabuzz.android.production');
        } else {
            window.open('https://apps.apple.com/us/app/campus-abuzz/id1475151665?mt=8');
        }
    };

    render() {
        let { group } = this.props.group;
        let sanitizedGroup = sanitizeDetails(group);

        /*if(isGettingMoment || Object.keys(moment).length === 0) {
            return (
                <div className="post__loading-indicator">
                    <LoadingIndicator />
                </div>
            );
        } else {*/
            return (
                <div className="hive">
                    <div className="hive--inner">
                        <Helmet>
                            <title>{group.name} is an Abuzz hive</title>
                            <meta name="description" content={group.description}/>
                            <meta property="og:url" content={process.env.REACT_APP_SITE_URL + this.props.match.url}/>
                            <meta property="og:title" content={group.name}/>
                            <meta property="og:description"
                                  content={group.description}/>
                            <meta property="og:image" content={generateImageUrl(group.artifact)}/>
                        </Helmet>

                        <div className="hive__header">
                            <div className="hive__header--inner">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 110" fill="none" height={110} width={100}>
                                    <clipPath id="clipPath">
                                        <path
                                            fill="red"
                                            d="M44.23,1.52a11.69,11.69,0,0,1,11.54,0L94.23,23.35A11.3,11.3,0,0,1,100,33.18V76.82a11.3,11.3,0,0,1-5.77,9.83L55.77,108.48a11.69,11.69,0,0,1-11.54,0L5.77,86.65A11.3,11.3,0,0,1,0,76.82V33.18a11.3,11.3,0,0,1,5.77-9.83Z"
                                        />
                                    </clipPath>
                                    <g clipPath="url(#clipPath)">
                                        <image
                                            x="0"
                                            y="0"
                                            width="100%"
                                            height="100%"
                                            preserveAspectRatio="xMidYMid slice"
                                            href={generateImageUrl(group.artifact, true)}
                                        />
                                    </g>
                                </svg>

                                <div className="hive__hive-name">
                                    {group.name}
                                </div>

                                {group.memberCount ? (
                                    <div className="hive__member-count">
                                        {group.memberCount} member{group.memberCount !== 1 ? 's' : ''}
                                    </div>
                                ) : null}

                                <div className="hive__join-link">
                                    <a href="#" onClick={this.handleLinkTap}>Join this Hive</a>
                                </div>
                            </div>
                        </div>

                        <div className="hive__about">
                            <h2>Overview</h2>

                            <div className="hive__description">
                                <ReactHashtag renderHashtag={hashtagValue => <Hashtag>{hashtagValue}</Hashtag>}>{group.description}</ReactHashtag>
                            </div>

                            <div className="hive__about-subtitle">
                                For people who like
                            </div>

                            <div className="hive__about-text">
                                {sanitizedGroup.interests.map((interest, i) => (
                                    <span>{interest.displayText || interest.description}{i < sanitizedGroup.interests.length - 1 ? ', ' : ''}</span>
                                ))}
                            </div>

                            <div className="hive__about-subtitle">
                                Membership
                            </div>

                            <div className="hive__about-text">
                                {group.enroll === 'O' ?
                                    'Open to anyone' :
                                    group.enroll === 'P' ?
                                        'Private' :
                                        group.enroll === 'A' ?
                                            'Auto' :
                                            null
                                }
                            </div>

                            <div className="hive__about-subtitle">
                                Live since
                            </div>

                            <div className="hive__about-text">
                                {moment(group.createdAt).format('MMMM D, YYYY')}
                            </div>
                        </div>
                    </div>
                </div>
            );
        //}
    }
}

export default connect(
    ({group}) => ({
        group
    }),
    dispatch => ({
        getGroup: (groupId) => dispatch(getGroup(groupId))
    }))(Group);
