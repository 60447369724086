import './Video.scss';

import React from 'react';
import PropTypes from 'prop-types';

const Video = ({artifact, containerPadding, style}) => {
    if(!artifact || !artifact.videoUrl || !artifact.height || !artifact.width) {
        return null;
    }

    const innerWidth = 600;

    const maxWidth = 600 - (containerPadding || 0);
    const ratio = artifact.width / artifact.height;
    const windowWidth = innerWidth > maxWidth ? maxWidth : innerWidth;
    let widthToUse = windowWidth;
    let heightToUse = widthToUse / ratio;
    let xPos = 0;

    console.log(ratio, widthToUse, heightToUse)
    if(heightToUse > widthToUse) {
        heightToUse = widthToUse;
        widthToUse = heightToUse * ratio;
        xPos = (windowWidth - widthToUse) / 2;
    }
    console.log(ratio, widthToUse, heightToUse)

    return (
        <div
            className="o-video"
            style={{paddingTop: heightToUse, ...style}}
        >
            <video
                className="c-video"
                controls
                style={{
                    height: heightToUse,
                    width: widthToUse,
                    left: xPos
                }}
            >
                <source src={artifact.videoUrl.replace('.m3u8', '.mp4')} type="video/mp4" />
            </video>
        </div>
    );
};

Video.propTypes = {
    artifact: PropTypes.object.isRequired,
    containerPadding: PropTypes.number,
    style: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.number,
    ])
};

export default Video;
