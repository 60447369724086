import { combineReducers } from 'redux';

import app from '../containers/App/store/reducers';
import deal from '../containers/Deal/store/reducers';
import event from '../containers/Event/store/reducers';
import group from '../containers/Group/store/reducers';
import marketplace from '../containers/Marketplace/store/reducers';
import moment from '../containers/Moment/store/reducers';
import thread from '../containers/Thread/store/reducers';
import topic from '../containers/Topic/store/reducers';

import * as types from './constants';

export const appReducer = combineReducers({
    app,
    deal,
    event,
    group,
    marketplace,
    moment,
    thread,
    topic
});

export const rootReducer = (state, action) => {
    if (action.type === types.RESET_GLOBAL_STATE) {
        state = undefined;
    }
    return appReducer(state, action);
};
