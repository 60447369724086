import './DownloadButtons.scss';

import React from 'react';
import PropTypes from 'prop-types';

const DownloadButtons = ({text}) => (
    <div className="download-buttons">
        {text ? (
            <div className="download-buttons__text">
                {text}
            </div>
        ) : null}

        <a className="download-buttons__app-store" href="https://apps.apple.com/us/app/campus-abuzz/id1475151665?mt=8">
            <img src="/img/apple-app-store-badge.png" alt="Download on the App Store" />
        </a>

        <a className="download-buttons__google-play" href="https://play.google.com/store/apps/details?id=com.campusabuzz.android.production&hl=en_US">
            <img src="/img/google-play-badge-dark.png" alt="Get it on Google Play" />
        </a>
    </div>
);

DownloadButtons.propTypes = {
    text: PropTypes.string
};

export default DownloadButtons;
