import Request from '../../../utils/request';
import PATHS from '../../../utils/paths';

import * as types from './constants';

export const clearDeal = () => ({
    type: types.CLEAR_DEAL
});

const getDealStart = () => ({
    type: types.GET_DEAL_START
});

const getDealSuccess = (data) => ({
    type: types.GET_DEAL_SUCCESS,
    data
});

const getDealFailure = (error) => ({
    type: types.GET_DEAL_FAILURE,
    error
});

export const setDeal = (data) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: types.SET_DEAL,
            data
        });
        resolve();
    });
};

export const getDeal = (postId) => (dispatch, getStore) => {
    dispatch(getDealStart());

    let request = new Request(process.env.token);

    return request.get(PATHS.deal.getDeal(postId))
        .then((response) => {
            dispatch(getDealSuccess(response.data.data));
            return response;
        })
        .catch((err) => {
            err.friendlyMessage = 'Unable to get deal.  Please try again';
            dispatch(getDealFailure(err));
            return err;
        })
}
