import clone from 'clone';

import * as types from './constants';

//const sampleMoment = {"postId":858,"groupId":1,"type":"M","description":"Landscape","enableRsvp":"N","status":"A","createdAt":"2020-09-13T14:15:47.000+0000","likeCount":0,"commentCount":0,"rsvpCount":0,"redeemCount":0,"isLiked":false,"isRsvp":false,"isOwner":true,"isRedeemable":false,"details":[],"location":{"name":"","address1":"","address2":"","city":"","state":"","zip":"","lat":"","lng":""},"artifact":{"artifactId":1709,"objectKey":"update-your-app-video.jpg","bucket":"public-bucket-dev-media-processing","format":"mov","type":"video","width":"1280","height":"720","baseUrl":"https://s3.amazonaws.com/","imageDirectories":["220","568","667","736","812","896","original"],"videoUrl":"https://public-bucket-dev-media-processing.s3.amazonaws.com/video/3orQceCV3JxRwrneVJ/3orQceCV3JxRwrneVJ.m3u8","thumbnailUrl":"https://public-bucket-dev-media-processing.s3.amazonaws.com/video/3orQceCV3JxRwrneVJ/3orQceCV3JxRwrneVJthumbnail.0000000.jpg"},"profile":{"profileId":3,"emailAddress":"joshbroton@gmail.com","lastName":"Brotonz","firstName":"Joshuas","middleName":"","about":"Wouldn’t you like to know?","invisible":"false","status":"A","createdAt":"2019-06-17T22:18:55.000+0000","artifact":{"artifactId":1700,"objectKey":"hpuTkVh2YZ5doeR7ur.jpg","bucket":"public-bucket-dev-media-processing","format":"jpg","type":"image","width":"810.8709677419355","height":"1080","baseUrl":"https://s3.amazonaws.com/","imageDirectories":["220","568","667","736","812","896","original"]}},"group":{"groupId":1,"name":"School","description":"Campus Default Group","enroll":"A","defaultGroup":"true","memberStatus":"M"}}

export const initialState = {
    //moment: clone(sampleMoment),
    moment: {},
    isGettingMoment: false,
    getMomentError: {}
};

function momentReducer(state = clone(initialState), action) {
    switch(action.type) {
        case types.CLEAR_MOMENT:
            return { ...state, moment: {} };
        case types.GET_MOMENT_START:
            return { ...state, isGettingMoment: true, getMomentError: {} };
        case types.GET_MOMENT_SUCCESS:
            return { ...state, isGettingMoment: false, moment: action.data };
        case types.GET_MOMENT_FAILURE:
            return { ...state, isGettingMoment: false, getMomentError: action.error };
        case types.SET_MOMENT:
            return { ...state, moment: action.data };
        default:
            return state;
    }
}

export default momentReducer;
