import './PostHeader.scss';

import React from 'react';

import Logo from '../Logo';

const PostHeader = () => (
    <header className="c-post-header">
        <Logo type="icon" />
    </header>
);

export default PostHeader;
