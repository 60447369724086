import './Icon.scss';

import React from 'react';
import PropTypes from 'prop-types';

const generateClass = (type, className) => {
    let classString = `c-icon a-icon--${type}`;
    if(className != null) {
        classString += ` ${className}`;
    }
    return classString;
};

const Icon = ({className, type}) => {
    switch(type) {
        case 'comment':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 14">
                    <path d="M3.375 10.5v-.75H1.75c-.548 0-1-.452-1-1v-7c0-.548.452-1 1-1h10.5c.548 0 1 .452 1 1v7c0 .548-.452 1-1 1H8.19l-.179.109-4.636 2.81V10.5z" strokeWidth={1.5} />
                </svg>
            );
        case 'comment-active':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 20">
                    <path d="M17.5,0H2.5A2.51,2.51,0,0,0,0,2.5v10A2.51,2.51,0,0,0,2.5,15H3.75v5L12,15h5.5A2.51,2.51,0,0,0,20,12.5V2.5A2.51,2.51,0,0,0,17.5,0Z"/>
                </svg>
            );
        case 'like':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 23 20">
                    <path d="M11.5,20.3l-8.6-8.4c0,0-0.2-0.1-0.4-0.3c-0.1-0.1-0.4-0.4-0.7-0.9C1.5,10.3,1.2,9.9,1,9.5C0.7,9,0.5,8.5,0.3,7.9C0.1,7.3,0,6.7,0,6.1c0-0.9,0.1-1.6,0.3-2.3L-0.8,4l2.4-2.4C2.7,0.5,4.2,0,6.1,0c0.5,0,1,0.1,1.5,0.2l6,0.6l1.6-0.5C15.8,0.1,16.3,0,16.9,0c1.9,0,3.4,0.5,4.5,1.6L23.8,4l-1.1-0.2C22.9,4.5,23,5.2,23,6.1c0,1.9-1,3.9-2.9,5.9L11.5,20.3z M2.8,3.3C2.3,4,2,4.9,2,6.1c0,0.4,0.1,0.8,0.2,1.2c0.2,0.5,0.3,0.9,0.5,1.2c0.2,0.4,0.5,0.7,0.7,1c0.3,0.4,0.5,0.6,0.6,0.7c0.1,0.1,0.1,0.1,0.2,0.2l0.1,0.1l7.2,7.1l7.2-7C20.2,9,21,7.5,21,6.1c0-1.2-0.3-2.1-0.8-2.8h-6.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.2-0.6,0.5-0.8,0.7l-1.4,1.4l-1.4-1.4C9.8,4,9.5,3.7,9.3,3.5C9.2,3.4,9.1,3.4,9.1,3.3H2.8z"/>
                </svg>
            );
        case 'like-active':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 23 20">
                    <path d="M21.37,1.61A6.12,6.12,0,0,0,16.86,0a5.05,5.05,0,0,0-1.62.28A6.52,6.52,0,0,0,13.7,1q-.71.48-1.23.89a11.92,11.92,0,0,0-1,.89,11.94,11.94,0,0,0-1-.89Q10,1.51,9.3,1A6.53,6.53,0,0,0,7.76.28,5.05,5.05,0,0,0,6.13,0a6.12,6.12,0,0,0-4.5,1.61A6,6,0,0,0,0,6.09a5.78,5.78,0,0,0,.3,1.8A7.94,7.94,0,0,0,1,9.47a11.52,11.52,0,0,0,.87,1.27q.49.62.71.85a4.26,4.26,0,0,0,.35.34l8,7.84a.8.8,0,0,0,1.13,0l8-7.81Q23,9,23,6.09A6,6,0,0,0,21.37,1.61Z"/>
                </svg>
            );
        case 'pin':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 20 31">
                    <path d="M10,0A9.94,9.94,0,0,0,0,10c0,6.64,7.78,15.5,10,21,2.22-5.54,10-14.39,10-21A9.94,9.94,0,0,0,10,0Zm0,15.06A5.16,5.16,0,0,1,4.89,10a5.11,5.11,0,0,1,10.22,0A5.32,5.32,0,0,1,10,15.06Z"/>
                </svg>
            );
        case 'upvote':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 15">
                    <path d="M8.2,15H3.7c-0.6,0-1-0.4-1-1V8H1C0.6,8,0.2,7.8,0.1,7.4C-0.1,7,0,6.6,0.3,6.3L6.2,0l5.5,6.3C12,6.6,12,7,11.9,7.4C11.7,7.8,11.4,8,11,8H9.2v6C9.2,14.6,8.8,15,8.2,15z M4.2,13.5h3.5v-7h2.2L6.2,2.2l-4,4.3h2.1L4.2,13.5L4.2,13.5z" />
                </svg>
            );
        case 'upvote-solid':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 12 15">
                    <path d="M8.2,15H3.7c-0.6,0-1-0.4-1-1V8H1C0.6,8,0.2,7.8,0.1,7.4C-0.1,7,0,6.6,0.3,6.3L6.2,0l5.5,6.3c0.3,0.3,0.3,0.7,0.2,1.1C11.7,7.8,11.4,8,11,8H9.2v6C9.2,14.6,8.7,15,8.2,15z"/>
                </svg>
            );
        default:
            return null;
    }
};

Icon.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired
};

export default Icon;
