import './Comment.scss';

import React from 'react';
import PropTypes from 'prop-types';
import ReactHashtag from 'react-hashtag';

import Avatar from '../Avatar';
import Date from '../Date';
import Hashtag from "../Hashtag";

const Comment = ({comment, profile, timestamp}) => (
    <div className="comment">
        {/*<Avatar className="comment__avatar" image={profile.artifact} />*/}

        <div className="comment__content">
            {/*<div className="comment__name">
                {profile.firstName}
            </div>*/}

            <div className="comment__text">
                <ReactHashtag renderHashtag={hashtagValue => <Hashtag>{hashtagValue}</Hashtag>}>{comment}</ReactHashtag> <Date date={timestamp} dontAppendText />
            </div>
        </div>
    </div>
);

Comment.propTypes = {
    comment: PropTypes.string.isRequired,
    profile: PropTypes.object.isRequired,
    timestamp: PropTypes.string
};

export default Comment;
