import Request from '../../../utils/request';
import PATHS from '../../../utils/paths';

import * as types from './constants';

export const clearGroup = () => ({
    type: types.CLEAR_GROUP
});

const getGroupStart = () => ({
    type: types.GET_GROUP_START
});

const getGroupSuccess = (data) => ({
    type: types.GET_GROUP_SUCCESS,
    data
});

const getGroupFailure = (error) => ({
    type: types.GET_GROUP_FAILURE,
    error
});

export const setGroup = (data) => (dispatch) => {
    return new Promise((resolve) => {
        dispatch({
            type: types.SET_GROUP,
            data
        });
        resolve();
    });
};

export const getGroup = (postId) => (dispatch, getStore) => {
    dispatch(getGroupStart());

    let request = new Request(process.env.token);

    return request.get(PATHS.group.getGroup(postId))
        .then((response) => {
            dispatch(getGroupSuccess(response.data.data));
            return response;
        })
        .catch((err) => {
            err.friendlyMessage = 'Unable to get group.  Please try again';
            dispatch(getGroupFailure(err));
            return err;
        })
}
