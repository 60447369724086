import clone from 'clone';

import * as types from './constants';

/*const sampleTopic = {
    "forumTopicId": 3918,
    "tenantId": 1,
    "name": "Private Test 1",
    "autoEnroll": "N",
    "createdByProfileId": 6,
    "ownerProfileId": 6,
    "privacyType": "private",
    threadCount: 41,
    memberCount: 11,
    "threads": [{
        "postId": 2191,
        "type": "T",
        "title": "Test of a thread",
        "description": 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in leo augue. Vestibulum cursus nunc sit amet justo imperdiet lobortis. Curabitur ultricies est odio, vel lacinia urna feugiat sed. Sed non mauris et felis efficitur dictum vitae ullamcorper tortor. Vestibulum ac sem neque. Vivamus sed facilisis velit. Nunc vitae nisl efficitur, congue tortor vitae, suscipit erat. Vestibulum aliquet sem et ante laoreet sollicitudin.',
        "price": null,
        "createdAt": "2021-09-13T17:51:01.000Z",
        "commentCount": 0,
        "likeCount": 0,
        "details": [],
        "artifacts": []
    }, {
        "postId": 2192,
        "type": "T",
        "title": "Another thread happening now",
        "description": 'Morbi rhoncus egestas venenatis. Praesent dignissim eget lacus eu laoreet. Aenean quis dui mauris. Aliquam erat volutpat. Phasellus molestie eget orci vel varius. Phasellus eget aliquam nisl. Aliquam erat volutpat. Phasellus ut tincidunt sapien.',
        "price": null,
        "createdAt": "2021-09-15T12:30:01.000Z",
        "commentCount": 0,
        "likeCount": 0,
        "details": [],
        "artifacts": []
    }]
}*/

export const initialState = {
    //topic: clone(sampleTopic),
    topic: {},
    isGettingTopic: false,
    getTopicError: {}
};

function marketplaceReducer(state = clone(initialState), action) {
    switch (action.type) {
        case types.CLEAR_TOPIC:
            return {...state, topic: {}};
        case types.GET_TOPIC_START:
            return {...state, isGettingTopic: true, getTopicError: {}};
        case types.GET_TOPIC_SUCCESS:
            return {...state, isGettingTopic: false, topic: action.data};
        case types.GET_TOPIC_FAILURE:
            return {...state, isGettingTopic: false, getTopicError: action.error};
        case types.SET_TOPIC:
            return {...state, topic: action.data};
        default:
            return state;
    }
}

export default marketplaceReducer;
